import { typeaheadPostalCodeCity } from '../address/capAdresse.js';
import { typeaheadAddress } from '../address/capAdresse.js';

$(document).ready(function () {
    const favoriteAddressBtn = $('.js-favoriteAddressBtn');
    const editAddressBtn = $('.js-editAddressBtn');
    const removeAddressBtn = $('.js-removeAddressBtn');
    const addAddressBtn = $('.js-addAddressBtn');

    if (favoriteAddressBtn.length) {
        $(document).on('click', '.js-favoriteAddressBtn', function (e) {
            let isFavorite = ($(this).attr('data-favorite') == true);

            if (isFavorite) {
                return;
            } else {
                const url = $(this).attr('data-url');

                if (!url.length) {
                    return;
                }

                $.ajax({
                    url: url,
                    type: 'PUT',
                    success: function (result) {
                        //update icons
                        $(this).attr('data-favorite', 1);
                        var svgIcon = $(this).find('svg');
                        svgIcon.addClass('fa');
                        svgIcon.addClass('selected-icon');
                        svgIcon.removeClass('far');
                        svgIcon.removeClass('unselected-icon');

                        //updates previous favorite
                        $(".js-favoriteAddressBtn").not($(this)).each(function (index, element) {
                            $(element).attr('data-favorite', 0);
                            var svgIcon = $(element).find('svg');
                            svgIcon.addClass('far');
                            svgIcon.addClass('unselected-icon');
                            svgIcon.removeClass('fa');
                            svgIcon.removeClass('selected-icon');

                            $(element).parent().find('.js-removeAddressBtn').removeClass('hidden');
                        });

                        //Déplacement de l'adresse au début de la liste
                        $('.card-address[data-id="' + $(this).attr('data-id') + '"]').prependTo('.js-addresses-list');

                        //update remove btn
                        $(this).parent().find('.js-removeAddressBtn').addClass('hidden');

                    }.bind(this)
                });
            }
        });
    }

    if (removeAddressBtn.length) {
        $(document).on('click', '.js-removeAddressBtn', function (e) {
            let url = $(this).attr('data-url');
            let modalTitle = $(this).attr('data-modalTitle');

            if (!url.length) {
                return;
            }

            let btnRemoveTrad = document.getElementById('BtnRemoveTrad').getAttribute('data-value');
            let btnCloseTrad = document.getElementById('BtnCloseTrad').getAttribute('data-value');

            bootbox.confirm({
                title: modalTitle,
                message: $(this).parent().parent().parent().find('.js-address-content').html(),
                buttons: {
                    confirm: {
                        label: btnRemoveTrad
                    },
                    cancel: {
                        label: btnCloseTrad
                    }
                },
                size: 'small',
                centerVertical: true,
                callback: function (result) {
                    if (result) {
                        $.ajax({
                            url: url,
                            type: 'DELETE',
                            success: function (result) {
                                $(this).parent().parent().parent().remove();
                            }.bind(this)
                        });
                    }
                }.bind(this)
            });
        });
    }

    //Create adress form
    if (addAddressBtn.length) {
        $(document).on('click', '.js-addAddressBtn', function (e) {
            let url = $(this).attr('data-url');
            let modalTitle = $(this).attr('data-modalTitle');
            let refresh = $(this).attr('data-refresh') == 'true';

            //get content for form
            $.ajax({
                url: url,
                type: 'GET',
                success: function (result) {

                    displayModal(result, modalTitle, refresh)
                    initForm(result)
                }
            });
        });
    }

    if (editAddressBtn.length) {
        $(document).on('click', '.js-editAddressBtn', function (e) {
            let url = $(this).attr('data-url');
            let modalTitle = $(this).attr('data-modalTitle');
            //get content for form
            $.ajax({
                url: url,
                type: 'GET',
                success: function (result) {
                    displayModal(result, modalTitle)
                    initForm(result)
                }
            });
        });
    }

    function initForm(_langueSite) {
        show_typique_field();
        activateFields();
        setTypeaheadAddress();
        $('#form_address').find('select').selectpicker();
        $('#form_address').find('[auto-format]').each(function (_idx, _e) {
            switch ($(_e).attr('auto-format')) {
                case 'capitalize':
                    $(_e).on('keyup', function (e) {
                        $(this).val($(this).val().toUpperCase());
                    });
                    break;
                case 'capitalize-word':
                    $(_e).on('keyup', function (e) {
                        $(this).val($(this).val().toLowerCase().capitalize_word());
                    });
                    break;
                case 'lowerize':
                    $(_e).on('keyup', function (e) {
                        $(this).val($(this).val().toLowerCase());
                    });
                    break;
            }
        });

        $('.pays_for_adresse').on('change', function () {
            $('.typeahead_cp_ville').val('').attr('value', '');
            $('.typeahead_adresse').val('').attr('value', '');
            $('.typeahead_lieu_dit').val('').attr('value', '');
            $('.typeahead_adresse').val('').attr('value', '');
            $('#complement').val('').attr('value', '');
            // _previous_flags_cp_ville = 0;
            show_typique_field();
        });
        $('#form_address').find('input, textearea, select').on('keypress', $.debounce(function () {
            validateFieldsForForm('form_address');
        }, 250));

        typeahead('.libelleAdresseTypeahead', 'libelle', $('.js-addresses-list').attr('data-locale'));

    }

    function displayModal(content, modalTitle, refresh = false) {

        let btnValidTrad = document.getElementById('BtnValidTrad').getAttribute('data-value');
        let btnCloseTrad = document.getElementById('BtnCloseTrad').getAttribute('data-value');

        bootbox.confirm({
            show: false,
            message: content,
            title: modalTitle,
            centerVertical: false,
            closeButton: false,
            buttons: {
                confirm: {
                    label: btnValidTrad
                },
                cancel: {
                    label: btnCloseTrad
                }
            },
            locale: document.documentElement.lang,
            callback: function (result) {
                if (result) {
                    var idAddress = $('#form_address').attr('data-id') || null;
                    if (idAddress != null) {
                        var action = 'edit-address';
                    } else {
                        var action = 'add-address';
                    }

                    var __valid = validateForm();
                    var __country = $('#pays :selected').attr('data-country-code') || 'FRA';
                    var __language = $('#pays :selected').attr('data-iso') || 'FR';
                    var __pays = $('#pays :selected').val() || 65;
                    var etatElement = $('#etat');

                    if (etatElement.length > 0) {
                        var __etat = $('#etat').children("option:selected").data()['valeuretat'];
                    } else {
                        var __etat = "";
                    }

                    if (__valid === true) {
                        $.ajax({
                            type: "POST",
                            url: '/client-adresse/api',
                            data: {
                                countryCode: __country,
                                languageCode: __language.toLowerCase(),
                                idAddress: idAddress,
                                action: action,
                                libelleAdresse: $('.modal-body input[name=libelle]').val(),
                                raisonSocialeAdresse: $('.modal-body input[name=raisonSociale]').val(),
                                nomAdresse: $('.modal-body input[name=nom]').val(),
                                prenomAdresse: $('.modal-body input[name=prenom]').val(),
                                adresse: $('.modal-body input[name=adresse]').val(),
                                complement: $('.modal-body input[name=complement]').val(),
                                codePostal: $('.modal-body input[name=CP]').val(),
                                ville: $('.modal-body input[name=ville]').val(),
                                lieu_dit: $('.modal-body input[name=lieu_dit]').val(),
                                etat: __etat,
                                pays: __pays
                            },
                            dataType: "json",
                            success: function (result) {
                                var favori = 0;
                                if (idAddress !== null) {
                                    favori = $('.card-address[data-id="' + idAddress + '"]').find('.js-favoriteAddressBtn').attr('data-favorite');
                                    //remove edited address
                                    $('.card-address[data-id="' + idAddress + '"]').remove();
                                }

                                if (favori == '1') {
                                    // ajout de l'adresse en début de liste 
                                    $('.js-addresses-list').prepend(result);
                                }
                                else {
                                    // ajout de l'adresse en fin de liste
                                    $('.js-addresses-list').append(result);
                                }

                                //close modal
                                bootbox.hideAll();

                                if (refresh) {
                                    window.location.reload();
                                }
                            }
                        });
                    }

                    return false; //prevent modal to close
                }
            }
        }).off("shown.bs.modal").modal("show");
    }

    function validateFieldsForForm(id) {
        var input = $('[form=' + id + '], #' + id + ' input, #' + id + ' select, #' + id + ' textarea');
        var fail = 0;
        // si un formulaire ou un élément trouvé
        if ($('#' + id).length !== 0 || input.length !== 0) {
            try {
                $(input).each(function () {
                    // si l'élément requit
                    if ($(this).prop('required')) {
                        // si l'element est visible
                        if ($(this).is(':visible')) {
                            var current_input = this;
                            $(this).classes(function (c) {
                                switch (c) {
                                    case 'validate[required]':
                                        fail += _validateNotEmpty(current_input);
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                    }
                });
            } catch (err) {
                // nothing
            }
            return (fail === 0);
        } else { // if nothing is found form is validate
            return true;
        }
    }

    function activateFields() {
        if ($(".typeahead_nom").val() != '') {
            $(".typeahead_prenom").prop("disabled", false);
        }
        if ($(".typeahead_nom").val() != '' && $(".typeahead_prenom").val() != '') {
            $(".typeahead_cp_ville").prop("disabled", false);
        }
        if ($(".typeahead_cp_ville").val() != '') {
            $(".typeahead_lieu_dit").prop("disabled", false);
            $(".typeahead_adresse").prop("disabled", false);
        }
        if ($(".typeahead_adresse").val() != '' && $(".typeahead_adresse").val().length > 3) {
            $(".typeahead_complement").prop("disabled", false);
            $('#btnAdresse').prop("disabled", false);
        }
    }

    function setTypeaheadAddress() {

        typeaheadPostalCodeCity();
        typeaheadAddress();

        $('.typeahead_nom, .typeahead_prenom, .typeahead_cp_ville, .typeahead_adresse').on('input', function () {
            activateFields();
        });
    }

    function show_typique_field() {
        var __val = parseInt($('select.pays_for_adresse').val());

        if (__val == 65) {
            $('.typeahead_lieu_dit').closest('.input-group').removeClass('hidden');
        } else {
            $('.typeahead_lieu_dit').closest('.input-group').addClass('hidden');
            $('.typeahead_lieu_dit').attr('value', '').val('');
        }
    }

    function validateForm() {
        var validOk = true;
        var _fc = $('.modal-body');
        _fc.find('.form-group').removeClass('has-error');
        var _champsErreur = '';
        var __idForm = $(_fc).find('form').attr('id');
        validOk = validateFieldsForForm(__idForm);

        if ($('input[name=libelle]').length > 0 && !$('input[name=libelle]').val()) {
            validOk = false;
            toggleError($('#libelle'));
            if (_champsErreur === '') {
                _champsErreur = '#libelle';
            }
        }

        if ($('input[name=ville]').length > 0 && !$('input[name=ville]').hasClass('hidden') && !$('input[name=ville]').val()) {
            validOk = false;
            toggleError($('#ville'));
            if (_champsErreur === '') {
                _champsErreur = '#ville';
            }
        }

        if ($('select[name=villeTmp]').length > 0 && !$('select[name=villeTmp]').hasClass('hidden') && !$('select[name=villeTmp]').val()) {
            validOk = false;
            toggleError($('#villeTmp'));
            _champsErreur = '#villeTmp';
        }

        if ($('input[name=adresse]').length > 0 && !$('input[name=adresse]').hasClass('hidden') && !$('input[name=adresse]').val()) {
            validOk = false;
            toggleError($('#adresse'));
            if (_champsErreur === '') {
                _champsErreur = '#adresse';
            }
        }

        if (validOk === true) {
            return true;
        } else {
            $(_champsErreur).focus();
            return false;
        }
    }

    function _validateNotEmpty(_el) {
        if ($(_el).val().trim() === '') {
            toggleError(_el);
            return 1;
        }
        else {
            return 0;
        }
    }

    function toggleError(_id) {
        $(_id).closest('.input-group').addClass('has-error').removeClass('has-success');
    }

    function typeahead(_inputClass, _inputId, _langueSite) {
        var _data = ['Adresse principale', 'Domicile', 'Bureau'];
        if (_langueSite !== 'fr_FR' && _langueSite !== 'fr') {
            _data = ['Main address', 'Home', 'Office'];
        }

        $('.modal-body ' + _inputClass).typeahead({
            hint: true,
            hightlights: false,
            items: 100,
            minLength: 1,
            scrollBar: true,
            showHintOnFocus: "all",
            fitToElement: true,
            matcher: function () {
                return true;
            },
            source: _data
        });
    }

});