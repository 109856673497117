import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['discountCode', 'codePromoBtn', 'validateBasketBtn', 'saveBasketModal', 'saveBasketReturn', 'saveBasketReturnProduct', 'oldBasketList', 'newBasketName', 'actionRadio', 'textPreOrder', 'radioAdd', 'radioReplace', 'addOrReplaceSelect'];

    handleChange() {
        console.log('handleChange');
        const oldBasketValue = this.oldBasketListTarget.value;
        const isDisabled = oldBasketValue === '';

        this.radioAddTarget.disabled = isDisabled;
        this.radioReplaceTarget.disabled = isDisabled;
        this.addOrReplaceSelectTarget.disabled = isDisabled;
    }

    validatePromo() {

        const _idBasket = document.querySelector('#discountCode').getAttribute('data-idBasket');
        const _code = this.discountCodeTarget.value;
        const code = _code === '' ? 'null' : _code;
        
        this.buttonOnLoad(this.codePromoBtnTarget);
        
        fetch(`/basket/validate-code/${_idBasket}/${code}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(t => {
            document.querySelector('.card-recap').innerHTML = t.total;
        });
    }

    validateBasket(event) {
        const idBasket = event.params.idBasket;
        let code = event.params.dataCode;
        code = code === '' ? 'null' : code;

        this.buttonOnLoad(this.validateBasketBtnTarget);

        fetch(`/basket/delivery/${idBasket}/${code}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(t => {
            if (t.dataLayer !== undefined) {
                document.body.insertAdjacentHTML('beforeend', t.dataLayer);
            }
            window.location = t.route;
        });
        
        if (window.sib) {
            window.sendinblue.track('cart_converted', {
                "email": window.sib.email_id,
                "FIRSTNAME": window.sib.firstname,
                "LASTNAME": window.sib.lastname,
            });
        }
    }

    validateBasketPreOrder(event) {
        event.preventDefault();
        const warningText = this.textPreOrderTarget.textContent;
        bootbox.confirm({
            message: warningText,
            locale: document.documentElement.lang,
            callback: (result) => {
                if (result) {
                    this.validateBasket(event);
                }
            }
        });
    }

    saveNewBasket() {
        const formData = new FormData();
        formData.append('basketOld', this.oldBasketListTarget.value);
        formData.append('action', document.querySelector('[name=nouveau_ajouter_remplacer]:checked').value);
        formData.append('new_name', this.newBasketNameTarget.value);

        fetch("/basket/create-new-saved-basket", {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(val => {
                document.body.insertAdjacentHTML('beforeend', val.dataLayer);
                if (val.message !== 'KO') {
                    // On remove la modal et le style
                    this.saveBasketModalTarget.remove();
                    document.querySelector('.modal-backdrop').remove();
                    document.body.removeAttribute('style');

                    const saveBasketReturnTargets = document.querySelectorAll('.saveBasketReturn');
                    saveBasketReturnTargets.forEach(target => {
                        target.textContent = val.message;
                    });
                }
            })
            .catch(error => {
                sendNotificationData('/basket/create-new-saved-basket', error.message, error,
                    `basketOld=${this.oldBasketListTarget.value}&action=${document.querySelector('[name=nouveau_ajouter_remplacer]:checked').value}&new_name=${this.newBasketNameTarget.value}`);
            });
    }

    saveNewBasketFromProduct(event) {
        let idProduit = document.querySelector('#btn_save_basket').dataset.id;
        let idRef = document.querySelector('#btn_save_basket').dataset.idRef;
        event.preventDefault();
        const formData = new FormData();
        formData.append('basketOld', this.oldBasketListTarget.value);
        formData.append('id_product', idProduit);
        formData.append('id_ref', idRef);
        formData.append('action', document.querySelector('[name=nouveau_ajouter_remplacer]:checked').value);
        formData.append('new_name', this.newBasketNameTarget.value);

        fetch("/product/save-new-basket", {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(val => {
                if (val.return === 'ok') {
                    // On remove la modal et le style
                    this.saveBasketModalTarget.remove();
                    document.querySelector('.modal-backdrop').remove();
                    document.body.removeAttribute('style');

                    this.toggleSaveButtonAndReturn('#btn_save_basket');
                    this.toggleSaveButtonAndReturn('.saveBasketReturn');
                    
                    setTimeout(() => {
                        this.toggleSaveButtonAndReturn('#btn_save_basket');
                        this.toggleSaveButtonAndReturn('.saveBasketReturn');
                    }, 4000);
                }
            })
    }

    loadModalSaveBasket(event) {
        event.preventDefault();
        const typeModal = event.params.type;

        fetch(`/load-modal-save-basket/${typeModal}`)
            .then(response => response.text())
            .then(typeModalContent  => {
                document.body.insertAdjacentHTML('beforeend', typeModalContent );

                let options = {
                    show: true,
                    keyboard: true
                };
                options.backdrop = 'static';
                var myModal = new bootstrap.Modal(document.getElementById('modalSaveBasket'), options);
                myModal.show();
    
                if (typeof $('.selectpicker').selectpicker === 'function') {
                    $('.selectpicker').selectpicker();
                }
                
                document.getElementById('modalSaveBasket').addEventListener('hidden.bs.modal', function () {
                    this.remove();
                });
            })
    }


    toggleSaveButtonAndReturn(id) {
        let element = document.querySelector(id);
        if (element) {
            element.classList.toggle('d-none');
        }
    }

    buttonOnLoad(element) {
        element.querySelector('span').classList.add('d-none');
        element.querySelector('.wait-onload').classList.remove('d-none', 'h-35px');
        element.querySelector('.wait-onload').classList.add('d-flex', 'h-24px');
        element.setAttribute('disabled', 'disabled');
    }
}