var _previous_flags_cp_ville = 0;

export function typeaheadPostalCodeCity() {
    $('.typeahead_cp_ville').typeahead({
        hint: true,
        hightlights: false,
        items: 100,
        minLength: 0,
        scrollBar: true,
        showHintOnFocus: "all",
        fitToElement: true,
        source: $.debounce(function (query, process) {
            var _input = $(this.$element).closest('.panel-body, .modal-body');
            var _pays = $(_input).find('.pays_for_adresse');
            var _option = $(_pays).find('option:selected');
            var country = $(_option).val() || '65';
            var useAPI = parseInt($(_option).attr('data-use-api-adresse') || 0, 10);

            if ($(this.$element).val() == '') {
                useAPI = 0;
            }

            // Use api
            if (useAPI > 0) {
                return $.ajax({
                        dataType: "json",
                        url: '/client-adresse/api',
                        method: 'GET',
                        data: {
                            countryCode: country,
                            inputFocus: 'codePostal',
                            inputOutput: query
                        }
                    })

                    .done(function (data) {
                        return process(typeaheadBack(data));
                    });
            }
        }, 500),
        matcher: function () {
            return $(this.$element).val() != '';
        },
        updater: function (item) {
            var _input = $(this.$element).closest('.panel-body, .modal-body');
            var _item = JSON.parse(item);
            if (_item.localityId != ''){
                $(_input).find('.typeahead_lieu_dit').attr('value', _item.localitySynonym).val(_item.localitySynonym);
                var complement_gen = (_item.buildingName).trim();
                $(_input).find('.typeahead_complement').attr('value', complement_gen).val(complement_gen);
                $(_input).find('.typeahead_cp_ville[data-type="CP"]').attr('value', _item.postalCode).val(_item.postalCode);
                $(_input).find('.typeahead_cp_ville[data-type="ville"]').attr('value', _item.locality).val(_item.locality);
                let _etat = $(_input).find('.selectpicker.etat_for_adresse  option[data-valeuretat="'+_item.localityInfo_3+'"]').attr('value');
                $(_input).find('.selectpicker.etat_for_adresse').selectpicker('val',_etat);
                _previous_flags_cp_ville = _item.localityFlags;
                $(_input).find('.typeahead_localityId').attr('value', _item.localityId).val(_item.localityId);
                if (($(_input).find('.typeahead_localityId').attr('value', _item.localityId).val(_item.localityId) !== '')) {
                    $(_input).find('.typeahead_adresse').focus();
                }
            }
            
            if ($(this.$element).attr('id') == 'CP') {
                return _item.postalCode;
            } else {
                return _item.locality;
            }
        }
    });
}

export function typeaheadAddress() {
    $('.typeahead_adresse').typeahead({
        hint: true,
        hightlights: true,
        items: 100,
        minLength: 0,
        scrollBar: true,
        showHintOnFocus: "all",
        fitToElement: true,
        source: $.debounce(function (query, process) {
            var _input = $(this.$element).closest('.panel-body, .modal-body');
            var _pays = $(_input).find('.pays_for_adresse');
            var _cp = $(_input).find('.typeahead_cp_ville[data-type="CP"]').val();
            var _ville = $(_input).find('.typeahead_cp_ville[data-type="ville"]').val();
            var _lieu_dit = $(_input).find('.typeahead_lieu_dit').val();
            var _localityId = $(_input).find('.typeahead_localityId').val();
            var _option = $(_pays).find('option:selected');
            var _country = $(_option).val() || '65';
            var useAPI = parseInt($(_option).attr('data-use-api-adresse') || 0, 10);

            if ($(this.$element).val() == '') {
                useAPI = 0;
            }

            // use api
            if (useAPI > 0) {
                return $.ajax({
                        dataType: "json",
                        url: '/client-adresse/api',
                        method: 'GET',
                        data: {
                            countryCode: _country,
                            inputFocus: 'adresse',
                            inputOutput: query,
                            codePostal: _cp,
                            Ville: _ville,
                            lieuDit: _lieu_dit,
                            localityId: _localityId
                        }
                    })

                    .done(function (data) {
                        return process(typeaheadBack(data));
                    });
            }
        }, 500),
        matcher: function () {
            return $(this.$element).val() != '';
        },
        updater: function (item) {
            var _e = this.$element;
            var _input = $(_e).closest('.panel-body, .modal-body');
            var _item = JSON.parse(item);
            var adresse_gen;
            if (_item.streetNumberPosition == 1) {
                if (_item.streetNumberExt == '') {
                    adresse_gen = (_item.streetNumber + ' ' + _item.streetName).trim();
                } else {
                    adresse_gen = (_item.streetNumber + ' ' + _item.streetNumberExt + ' ' + _item.streetName).trim();
                }
                if (_item.numeroBoite) {
                    adresse_gen += ' BTE ' + _item.numeroBoite;
                }
            } else {

                if (_item.streetNumberExt == '') {
                    adresse_gen = (_item.streetName + ' ' + _item.streetNumber).trim();
                } else {
                    adresse_gen = (_item.streetName + ' ' + _item.streetNumber + ' ' + _item.streetNumberExt).trim();
                }
                if (_item.numeroBoite) {
                    adresse_gen += ' BTE ' + _item.numeroBoite;
                }
            }
            if (_item.streetFlags == 4) {
                $(_input).find('.typeahead_complement').attr('value', _item.buildingName).val(_item.buildingName);
            }
            if (_previous_flags_cp_ville == 0 || _previous_flags_cp_ville == 1) {
                $(_input).find('.typeahead_lieu_dit').attr('value', _item.localitySynonym).val(_item.localitySynonym);
                $(_input).find('.typeahead_cp_ville[data-type="CP"]').attr('value', _item.postalCode).val(_item.postalCode);
                $(_input).find('.typeahead_cp_ville[data-type="ville"]').attr('value', _item.locality).val(_item.locality);
                let _etat = $(_input).find('.selectpicker.etat_for_adresse  option[data-valeuretat="'+_item.localityInfo_3+'"]').attr('value');
                $(_input).find('.selectpicker.etat_for_adresse').selectpicker('val',_etat);
                $(_input).find('.typeahead_localityId').attr('value', _item.localityId).val(_item.localityId);
            }
            $(_input).find('.typeahead_adresse').attr('value', adresse_gen).val(adresse_gen);
            if ($(_input).find('.typeahead_adresse').focus()) {
                $(_input).find('.typeahead_complement').attr('value', _item.buildingName).val(_item.buildingName);
                $(_input).find('.typeahead_complement').focus();
            }
            return adresse_gen;
        }
    });
}

export function typeaheadBack(data) {
    var $items = new Array(0);
    for (var i in data) {
        var group = {
            name: data[i].inputOutput,
            inputOutput: data[i].inputOutput,
            locality: (data[i].locality == '')?data[i].inputOutput.slice(0,-1):data[i].locality,
            postalCode: (data[i].postalCode == '')?data[i].inputOutput.slice(0,-1):data[i].postalCode,
            localitySynonym: data[i].localitySynonym,
            streetName: data[i].streetName,
            streetNumber: data[i].streetNumber,
            streetNumberExt: data[i].streetNumberExt,
            localityInfo_3: data[i].localityInfo_3,
            streetSynonym: data[i].streetSynonym,
            localityFlags: data[i].localityFlags,
            localityId: data[i].localityId,
            streetFlags: data[i].streetFlags,
            buildingName: data[i].buildingName,
            streetNumberPosition: data[i].streetNumberPosition,
            numeroBoite: data[i].numeroBoite,
            toString: function () {
                return JSON.stringify(this);
            },
            toLowerCase: function () {
                return this.name.toLowerCase();
            },
            indexOf: function (string) {
                return String.prototype.indexOf.apply(this.name, arguments);
            },
            replace: function (string) {
                var value = '';
                value += this.name;
                if (typeof (this.level) !== 'undefined') {
                    value += ' <span>';
                    value += this.level;
                    value += '</span>';
                }
                return String.prototype.replace.apply('<div>' + value + '</div>', arguments);
            }
        };
        $items.push(group);
    }
    return $items;
}
