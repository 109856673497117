import { debounce } from 'throttle-debounce';

$(function () {

    let inputCurrentCapProposeMail = null;

    const capEncyProposeMails = debounce(1000, (input) => {
        let responseFetchCapEncy = $.ajax({
            url: '/capency/propose-mails',
            type: 'POST',
            data: {
                'mail' : input
            },
            success: function (options) {
                document.getElementById('mail_propositions_list').innerHTML = null;
                options.forEach((option) => {
                    document.getElementById('mail_propositions_list').innerHTML += "<option>" + option + "</option>";
                })
                
            },
            error: function (jqXHR, textStatus, errorThrown) {
                sendNotificationData('/capency/propose-mails', textStatus, errorThrown,'mail=' + input);     
            }
        })
    })

    const verifyEmailValiditySponsorShip = debounce(500, (input) => {
        let responseFetchVerifyEmailSponsorShip = $.ajax({
            url: '/sponsorship/verify-email',
            type: 'POST',
            data: {
                'mail': input
            },
            success: function (validity) {

                document.getElementById('sponsorship_invalid_email_filter').classList.add('d-none');
                document.getElementById('sponsorship_invalid_email').classList.add('d-none');
                document.getElementById('sponsorship_valid_email').classList.add('d-none');

                if (validity.invalid_mail) {
                    document.getElementById('sponsorship_invalid_email_filter').classList.remove('d-none');
                } else {
                    if (validity.already_exist) {
                        document.getElementById('sponsorship_valid_email').classList.remove('d-none');
                    } else {
                        document.getElementById('sponsorship_invalid_email').classList.remove('d-none');
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                sendNotificationData('/sponsorship/verify-email', textStatus, errorThrown,'mail=' + input);     
            }
            
        })
    })  

    document.querySelectorAll("[list='mail_propositions_list']").forEach((item) => {
        item.addEventListener('keyup', function () {
            // si l'input est différent
            if (inputCurrentCapProposeMail !== item.value && item.value !== "") {
                inputCurrentCapProposeMail = item.value;
                capEncyProposeMails(item.value);
            }
        });
    })

    let sponsorshipEmail = document.getElementById('sponsorship_email');
    if (sponsorshipEmail) {
        sponsorshipEmail.addEventListener('keyup', function () {
            verifyEmailValiditySponsorShip(this.value);
        })
    }

});