$(document).ready(function () {
    var _mc = $('#mon_compte');
    if ($(_mc).find('#login').val() != '' || $(_mc).find('#password').val() != '') {
        //validationFormulaire('wmon_compte');
    }
    // chargement sign in google si besoin
    if (typeof gapi != "undefined") {
        gapi.load('auth2', function () {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            auth2 = gapi.auth2.init({
                client_id: __php_googleApplicationIdentification,
                cookiepolicy: 'single_host_origin',
            });
        });
    }

    $('.connect').on('click', function () {
        let that = this;
        showModalLogin(that);
    })

    showModalLogin = (that = null) => {

        if (that == null) {
            document.querySelector('.connect').click();
        }

        if (!document.body.classList.contains('modal-open')) {

            var redirect = $(that).attr('data-redirect') || '';
            document.body.className = 'modal-open';

            window.location = redirect;
        }
    }
    init();

});

function init() {

    if (!$('#mon_compte').find('#pwdforgot').hasClass('pageLogin')) {
        $('#mon_compte').find('#pwdforgot').on('click', function () {
            $(this).addClass('pageLogin');
            $('#lost_pwd').toggle();
            $('#mailPerdu').val(($('#login').val()));
            $('#mon_compte').toggle();

        });
    }

    var _lp = $('#lost_pwd');
    if (!$(_lp).find('#pwdcancel').hasClass('pageLogin')) {
        _lp.find('#pwdcancel').on('click', function () {
            $(this).addClass('pageLogin');
            $('#lost_pwd').toggle();
            $('#mon_compte').toggle();
        });
    }


    const text_send = $('#send_forgetPwd').find('p').text();
    _lp.on('submit', function () {
        _lp.find('.form-group').removeClass('has-error');
        _lp.find('.err').hide();
        $('#send_forgetPwd').hide();
        if (_lp.find('input[name=mailPerdu]').val().length > 0) {
            $.ajax({
                type: "POST",
                url: '/resetPassword',
                data: {
                    mail: _lp.find('input[name=mailPerdu]').val()
                },
                success: function (response) {
                    $('#send_forgetPwd').find('p').text(
                        text_send + _lp.find('input[name=mailPerdu]').val()
                    );
                    $('#send_forgetPwd').show();
                    return false;
                },
                error: function (ret) {
                    $('#send_forgetPwd').show();
                    _lp.find('input[name=mailPerdu]').closest('.input-group').addClass('has-error');
                }
            });
        } else {
            _lp.find('input[name=mailPerdu]').closest('.input-group').addClass('has-error');
        }
        return false;
    });

    /* Compte pro */

    let _lp_p = $('#lost_pwd_pro');
    let _accountPro = $('#mon_compte_pro');

    _accountPro.find('#pwdforgotPro').on('click', function () {
        _lp_p.toggle();
        _accountPro.toggle();
    });

    _lp_p.find('#pwdcancelpro').on('click', function () {
        _lp_p.toggle();
        _accountPro.toggle();
    });

    const text_send_pro = $('#send_forgetPwd_pro').find('p').text();
    _lp_p.on('submit', function () {
        _lp_p.find('.form-group').removeClass('has-error');

        if (_lp_p.find('input[name=mailPerduPro]').val().length > 0) {
            $.ajax({
                type: "POST",
                url: '/resetPassword',
                data: {
                    mail: _lp_p.find('input[name=mailPerduPro]').val()
                },
                success: function (response) {
                    $('#send_forgetPwd_pro').find('p').text(
                        text_send_pro + _lp_p.find('input[name=mailPerduPro]').val()
                    );
                    $('#send_forgetPwd_pro').show();
                    return false;
                },
                error: function (ret) {
                    $('#send_forgetPwd_pro').show();
                    _lp_p.find('input[name=mailPerduPro]').closest('.form-group').addClass('has-error');
                }
            });
        } else {
            _lp_p.find('input[name=mailPerduPro]').closest('.form-group').addClass('has-error');
        }
        return false;
    });

    /*  ---  */

    /* Submit connection */

    
    $('.modal-body form #submit-connect').on('click', function (e) {
        if($("#connect_account").is(":checked")){
            e.preventDefault();
            let form = $(".modal-body #mon_compte");
            submitConnect(form);
        }
    });
   

    $('#submit-connect-pro').on('click', function (e) {
        e.preventDefault();
        let form_pro = $("#mon_compte_pro");
        submitConnect(form_pro);
    });
}


function submitConnect(form) {
    form.find('.form-group').removeClass('has-error');
    $('#error_message').hide();
    if (form.find('input[name=email]').val().length > 0 && form.find('input[name=password]').val().length > 0) {
        if (form.find('input[name=email]').val().toLowerCase() === 'attente@123roulement.com') {
            form.find('input[name=email]').closest('.input-group').addClass('has-error');
            return false;
        } else {
            $.ajax({
                type: "POST",
                url: "/login",
                data: {
                    email: form.find('input[name=email]').val(),
                    password: form.find('input[name=password]').val(),
                    _csrf_token: form.find('input[name=_csrf_token]').val(),
                    redirect: form.find('input[name=redirect]').val()
                },
                success: function (response) {
                    if (response.redirect != '' && response.redirect != 'error' && response.redirect !== null) {
                        //datalayerConnexion(response);
                        if (response.dataLayer) {
                            $('body').append(response.dataLayer);
                        }
                        window.location.assign(response.redirect);
                    } else if (response.redirect == '') {
                        if (response.dataLayer) {
                            $('body').append(response.dataLayer);
                        }
                        //datalayerConnexion(response);
                        if (location.pathname == "/login") {
                            document.location.href = "/";
                        } else {
                            location.reload();
                        }
                    }else{
                        location.reload();
                    }
                },
                error: function (ret) {
                    if(ret.status == 429){
                        $('#error_message_limit').show();
                        setTimeout(function() {
                            if (ret.dataLayer) {
                                $('body').append(ret.dataLayer);
                            }
                            //datalayerConnexion(ret);
                            if (location.pathname == "/login") {
                                document.location.href = "/";
                            } else {
                                location.reload();
                            }
                        }, 5000);
                    }
                    else if (form.attr("id") == "mon_compte") {
                        $('#error_message').show();

                    } else if (form.attr("id") == "mon_compte_pro") {
                        $('#error_message_pro').show();
                    }
                    form.find('input[name=email]').closest('.input-group').addClass('has-error');
                    form.find('input[name=password]').closest('.input-group').addClass('has-error');
                }
            });
        }
    } else {
        form.find('input[name=email]').closest('.input-group').addClass('has-error');
        form.find('input[name=password]').closest('.input-group').addClass('has-error');
    }
    return false;

}

function datalayerConnexion(res) {
    if (res.customer) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'audience',
            user: {
                id: res.customer.id, // ID unique
                isLoggedIn :'yes', // loggué ou non
                type : (res.customer.isPro ? 'professionnel' : 'particulier'), // ou professionnel ou admin ou association
                customer: res.customer
            }
        });
    }
}