validFormMdp = () => {

    let form = document.querySelector('#form_modif_password');

    $.ajax({
        type: "POST",
        cache: false,
        url: form.getAttribute('action'),
        dataType: "json",
        data: {
            loginContact:   $(form).find('input[name=loginContact]').val(),
            oldMdpContact:  $(form).find('input[name=oldMdpContact]').val(),
            mdpContact:     $(form).find('input[name=mdpContact]').val(),
            mdpContact2:    $(form).find('input[name=mdpContact2]').val(),
        },
        success: function (res) {
            if (res.status == 0) {
                form.querySelector('.err').innerText = res.message;
                form.querySelector('.err').classList.remove('d-none');
            } else {
                document.querySelector('.modal').click()
                window.location.reload();
            }
        },
    });

}